import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import parkEquilio from "../images/sponsor/parkEquilio.png"
import gelateriaFerrari from "../images/sponsor/gelateriaFerrari.png"
import impertek from "../images/sponsor/impertek.png"
import canella from "../images/sponsor/canella.png"

const sponsors = [
  {
    label: "Casa Vinicola Canella",
    imgPath: canella,
  },
  {
    label: "Park Equilio Jesolo",
    imgPath: parkEquilio,
  },
  {
    label: "Gelateria Ferrari San Donà di Piave",
    imgPath: gelateriaFerrari,
  },
  {
    label: "Impertek",
    imgPath: impertek,
  },
]

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  squadre: {
    paddingTop: "65px",
    paddingBottom: "45px",
  },
  tabsMenu: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    width: "100%",
    maxWidth: "20vw",
    height: "auto",
    objectFit: "contain",
  },
}))

const Sponsor = () => {
  const classes = useStyles()

  return (
    <Layout title="Sponsor">
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container>
              <Grid item xs={7}>
                <h1
                  style={{
                    color: "#fe5f55",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Sponsor
                </h1>
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Gli sponsor che supportano l'associazione di Jesolo San Donà
                  Basket
                </p>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.squadre}>
          <Container>
            <Grid
              container
              spacing={5}
              style={{ justifyContent: "space-between" }}
            >
              {sponsors.map((sponsor, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <img
                    className={classes.img}
                    src={sponsor.imgPath}
                    alt={sponsor.label}
                  />
                  <h3
                    style={{
                      color: "#f2545b",
                      fontFamily: "Montserrat",
                      fontDisplay: "swap",
                    }}
                  >
                    {sponsor.label}
                  </h3>
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default Sponsor
